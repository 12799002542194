import Cookies from 'js-cookie';
import React, { useState } from 'react';

interface IFetchResult {
  success: boolean,
  message: String | null;
}

const useFetchLogin = (): [boolean, (senha: string, usuario: string) => Promise<IFetchResult>] => {
  const [loading, setLoading] = useState<boolean>(false);


  async function fetchLogin(senha: string, usuario: string): Promise<IFetchResult> {
    try {
      setLoading(true);

      const result: IFetchResult = {
        success: false,
        message: null
      }

      const body = {
        password: senha,
        userName: usuario
      }

      const configFetch = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      }


      const apiTest = 'https://api.grupohk.com.br:5007/login'
      const apiBeta = 'https://apidemo.grupohk.com.br:5050/login'


      const response = await fetch(apiTest, configFetch);

      setLoading(false);

      if (response.status === 401) result.message = 'Usuário não autorizado';
      else if (response.status === 500) result.message = 'Erro Interno no Servidor. Tente novamente';
      else if (response.status === 200) {
        const data = await response.json();
        Cookies.set('@sefip', data.token, { expires: 1 });
        window.location.href = '/';
      }


      if (!result.message) {
        result.success = true;
        return result;
      }

      return result;

    } catch (error) {
      setLoading(false);
      return {
        success: false,
        message: "Erro: " + error
      }
    }
  }


  return [loading, fetchLogin];
}

export default useFetchLogin;