import React, { ChangeEvent, DragEventHandler, useRef, DragEvent, useState } from 'react';

import { IoIosCloudUpload } from "react-icons/io"
import { IoExitOutline } from "react-icons/io5"
import BeatLoader from "react-spinners/BeatLoader";

interface IProps {
  loading: boolean;
  version: 'v1' | 'v2'
  handleUpload: (file: any, version: 'v1' | 'v2') => void;
}

const UploadSpace: React.FC<IProps> = ({ loading, handleUpload, version }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      handleUpload(e.target.files[0], version);
    }
  }


  function openFile() {
    if (inputRef.current) inputRef.current.click();
  }



  function onDraEnter(event: DragEvent) {
    setDragging(true);
  }

  function onDraLeave(event: DragEvent) {
    setDragging(false);
  }

  function onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    if (event.dataTransfer.files.length > 0) {
      handleUpload(event.dataTransfer.files[0], version);
    }
  }


  function onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }


  return (
    <div onDragEnter={onDraEnter} onDragLeave={onDraLeave} onDrop={onDrop} onDragOver={onDragOver} className={`
      w-2/3 h-1/3 bg- border-sky-700 border-2 rounded-lg border-dashed 
      flex flex-col justify-center items-center
       bg-white  ${dragging ? 'bg-slate-400' : null}
       transition-all    
       mb-[20px]    
       relative
      `}
    >

      <span className={`
        absolute
        top-10
        left-10
        text-zinc-400
        block
        text-[40px]
      `}>{version}</span>

      {!loading && <>
        <IoIosCloudUpload size={50} color={dragging ? '#fff' : '#0369a1'} />
        <span className={`font-light tracking-wide text-xl  ${dragging ? 'text-slate-50' : null}`}>Arraste e solte seu arquivo aqui</span>
        {!dragging && <span className='font-light text-neutral-500 tracking-wide text-xl'>ou</span>}
        {!dragging && <button onClick={openFile} className='transition bg-slate-200 px-3 my-2 font-light rounded-lg cursor-pointer hover:bg-slate-100'>Escolha o arquivo</button>}
        <input accept='.pdf' type='file' className='hidden' ref={inputRef} onChange={onFileChange} />
      </>
      }


      {loading && <>
        <BeatLoader color={"#0369a1"} />
        <span className='mt-8'>Processando solicitação ...</span>
        <span className='mt-3 font-light text-sm'>Não se preocupe. Isso pode levar um tempo</span>
      </>}

    </div>
  )
}

export default UploadSpace;