import React, { useEffect, useState } from 'react';
import Home from './pages/home';
import Login from './pages/login';
import Cookies from 'js-cookie';


function App() {
  const [logged, setLogged] = useState<boolean>(false);
  useEffect(() => {
    const token = Cookies.get('@sefip') || false;
    setLogged(!!token)
  }, [])

  return (
    logged ? <Home /> : <Login />
  );
}

export default App;
