import React from 'react';
import styles from "./loginstyles.module.css"
import { useForm, SubmitHandler } from "react-hook-form"
import { CiLock } from "react-icons/ci"
import Cookie from "js-cookie"
//@ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import BeatLoader from "react-spinners/BeatLoader";
import useFetchLogin from '../../services/fetch-login';


interface IInputs {
  usuario: string,
  senha: string
}

interface IFetch {
  password: string;
  userName: string;
}

const Login: React.FC = () => {
  const { register, formState: { errors }, handleSubmit } = useForm<IInputs>();
  const [loading, fetchLogin] = useFetchLogin();


  const submitFormLogin: SubmitHandler<IInputs> = async (dataForm) => {
    try {
      const response = await fetchLogin(dataForm.senha, dataForm.usuario);

      if (!response.success) {
        return NotificationManager.error(response.message)
      }

    } catch (error) {
      throw new Error('submitFormLogin: ' + error)
    }
  }




  return (
    <div className='h-screen w-screen flex justify-center flex-col items-center bg-slate-100'>
      <div className='bg-white w-screen p-4 absolute top-0 flex justify-center items-center shadow-sm'>
        <h1 className='text-2xl opacity-75'>Separador SEFIP</h1>
      </div>

      <div className='w-1/4 bg-white shadow-xl rounded-lg p-4'>
        <div className='flex flex-row justify-center mb-4'>
          <h2 className='text-center text-3xl opacity-75'>Login</h2>
          <CiLock />
        </div>

        <p className='text-center text-xs mt-2 mb-8 text-gray-400'>Bem vindo(a) ao Separador SEFIP. Insira os dados de login abaixo para acessar a aplicação</p>
        <form onSubmit={handleSubmit(submitFormLogin)} className='flex flex-col  items-center '>

          <div className='w-full mb-4'>
            <input placeholder='Usuário' className=' border-2 font-light w-full p-3 bg-gray-100 rounded-lg outline-none'  {...register('usuario', { required: true })} />
            {errors.usuario && <span className='text-red-600  font-light m-0'>campo obrigatório</span>}
          </div>

          <div className='w-full'>
            <input type="password" placeholder='Senha' className='font-light border-2 w-full p-3 bg-gray-100 rounded-lg outline-none' {...register('senha', { required: true })} />
            {errors.senha && <span className='text-red-600  font-light m-0'>campo obrigatório</span>}
          </div>
          <input disabled={loading} type={'submit'} value={loading ? "Aguarde ..." : 'Acessar'} className={`mt-4 w-full p-2 bg-[#036aa1] rounded-lg text-white hover:bg-[#2f89b9] cursor-pointer transition-all`} />
        </form>
      </div>
      <span className='absolute bottom-2 text-slate-400'>Powered by Grupo HK</span>
      <NotificationContainer />
    </div>
  )
}

export default Login;