import Cookies from 'js-cookie';
import React, { useState } from 'react';

interface IFetchResult {
  success: boolean,
  blob?: Blob,
  fileName?: string,
  notAuth?: boolean;
}


const BASE_URL_TESTE = 'https://api.grupohk.com.br:5007'
const BASE_URL_PRODUCAO = 'https://apidemo.grupohk.com.br:5050'



const useFetchFile = (): [boolean, boolean, (file: Blob, version: 'v1' | 'v2') => Promise<IFetchResult>] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingV2, setLoadingV2] = useState<boolean>(false);


  async function fetchFile(file: any, version: 'v1' | 'v2'): Promise<IFetchResult> {
    try {
      if (version === 'v1')
        setLoading(true);
      else
        setLoadingV2(true)

      let fileName = '';

      const formData = new FormData();

      fileName = file.name.replace(/\./g, '') + `_${Date.now()}.zip`;

      formData.append('uploaded_file', file);

      const config = {
        method: 'POST',
        body: formData,
        headers: {
          'authorization': `Bearer ${Cookies.get('@sefip')}`
        },
      }


      const apiTest = 'https://api.grupohk.com.br:5007/separasefip'
      const apiBeta = 'https://apidemo.grupohk.com.br:5050/separasefip'

      const url = version === 'v1' ? BASE_URL_TESTE + '/separasefip' : BASE_URL_TESTE + '/separasefipV2'

      const response = await fetch(url, config)


      if (version === 'v1')
        setLoading(false);
      else
        setLoadingV2(false)

      if (response.status === 403) {
        return {
          success: false
        }
      } else if (response.status === 401) {
        return {
          success: false,
          notAuth: true
        }
      } else if (response.status === 200) {
        const _blob: Blob = await response.blob();
        return {
          success: true,
          blob: _blob,
          fileName
        };
      } else {
        return {
          success: false,
          notAuth: false
        }
      }

    } catch (error) {
      if (version === 'v1')
        setLoading(false);
      else
        setLoadingV2(false)
      return {
        success: false
      }
    }
  }


  return [loading, loadingV2, fetchFile];
}

export default useFetchFile;